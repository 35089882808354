exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-algemene-voorwaarden-js": () => import("./../../../src/pages/algemene-voorwaarden.js" /* webpackChunkName: "component---src-pages-algemene-voorwaarden-js" */),
  "component---src-pages-bedankt-js": () => import("./../../../src/pages/bedankt.js" /* webpackChunkName: "component---src-pages-bedankt-js" */),
  "component---src-pages-codereview-guidelines-js": () => import("./../../../src/pages/codereview-guidelines.js" /* webpackChunkName: "component---src-pages-codereview-guidelines-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-diensten-js": () => import("./../../../src/pages/diensten.js" /* webpackChunkName: "component---src-pages-diensten-js" */),
  "component---src-pages-extra-js": () => import("./../../../src/pages/extra.js" /* webpackChunkName: "component---src-pages-extra-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offerte-aanvragens-js": () => import("./../../../src/pages/offerte-aanvragens.js" /* webpackChunkName: "component---src-pages-offerte-aanvragens-js" */),
  "component---src-pages-over-mij-js": () => import("./../../../src/pages/over-mij.js" /* webpackChunkName: "component---src-pages-over-mij-js" */)
}

